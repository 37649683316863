//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    dataWidgetId() {
      if (this.isMx) return 'HouizKuqTW3oJZTI';
      return 'H5kEAehSRAK6Drtt';
    },
  },
  // data() {
  //   return {
  //     interval: null,
  //   }
  // },
  // async mounted() {
  //   let arrows = await this.waitForArrows()
  //   this.$refs.widget?.firstChild.firstChild.firstChild.firstChild.classList.add('review-videos__arrows')
  //   let leftArrow = arrows[arrows.length - 2]
  //   leftArrow.setAttribute(this.$options._scopeId, "");
  //   leftArrow.classList.add('review-videos__arrow')
  //   leftArrow.classList.add('review-videos__arrow--left')
  //   let rightArrow = arrows[arrows.length - 1]
  //   rightArrow.setAttribute(this.$options._scopeId, "");
  //   rightArrow.classList.add('review-videos__arrow')
  //   rightArrow.classList.add('review-videos__arrow--right')
  // },
  // methods: {
  //   findArrows() {
  //     return this.$refs.widget?.firstChild?.firstChild?.firstChild?.firstChild?.childElementCount > 2
  //   },
  //   getArrows() {
  //     return this.$refs.widget?.firstChild.firstChild.firstChild.firstChild.children
  //   },
  //   waitForArrows() {
  //     return new Promise(async (resolve) => {
  //       this.interval = setInterval(() => {
  //         if (this.findArrows()) {
  //           clearInterval(this.interval)
  //           this.interval = null
  //           resolve(this.getArrows())
  //         }
  //       }, 1000)
  //     })
  //   },
  // },
  // beforeDestroy() {
  //   this.interval && clearInterval(this.interval)
  // },
  mounted() {
    if (process.client) {
      window.GetReview?.API?.reset();
    }
  },
};
