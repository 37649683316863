//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    directions() {
      const result = [];
      for (const direction of this.$store.state.courses.activeDirections) {
        if (direction.active && direction.coursesCount) {
          result.push({
            name: direction.name,
            slug: direction.slug,
          });
        }
      }
      this.debug({ name: 'comp directions' }, '%O', result);
      return result;
    },
  },
};
