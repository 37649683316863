//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViewportMixin from '@/mixins/viewport.mixin';
import SwiperMixin from '~/mixins/swiper.mixin.js';
import ArrowIcon from '~/assets/svg/ic-arrow-right.svg?inline';

export default {
  components: { ArrowIcon },
  mixins: [ViewportMixin, SwiperMixin],
  computed: {
    courses() {
      let country = this.$nuxt.$config.COUNTRY || 'br';
      if (!['mx', 'br'].includes(country)) country = 'br';

      let courses = [];
      if (country === 'mx') {
        courses = [
          {
            img: require('~/assets/images/index1/popular/course_1.jpg'),
            id: 'paisajismo',
            category: 'design',
            duration: 5,
            link: '/paisajismo',
          },
          {
            img: require('~/assets/images/index1/popular/course_2.jpg'),
            id: 'professionInteriorDesigner',
            category: 'design',
            duration: 12,
            link: '/profesion-de-interiores',
          },
          {
            img: require('~/assets/images/index1/popular/course_3.jpg'),
            id: 'illustrationDigital',
            category: 'design',
            duration: 5,
            link: '/ilustracion-digital',
          },
          {
            img: require('~/assets/images/index1/popular/course_4.jpg'),
            id: 'professionUXUIDesigner',
            category: 'design',
            duration: 10,
            link: '/profesion-disenador-ux-ui',
          },
          {
            img: require('~/assets/images/index1/popular/course_5.jpg'),
            id: 'professionDataAnalyst',
            category: 'programming',
            duration: 12,
            link: '/analista-de-datos',
          },
          {
            img: require('~/assets/images/index1/popular/course_6.jpg'),
            id: 'fashionMarketing',
            category: 'fashion',
            duration: 12,
            link: '/fashion-marketing',
          },
          {
            img: require('~/assets/images/index1/popular/course_7.jpg'),
            id: 'courseCopywriting',
            category: 'marketing',
            duration: 5,
            link: '/copywriting',
          },
          {
            img: require('~/assets/images/index1/popular/course_8.jpg'),
            id: 'professionGraphicDesigner',
            category: 'design',
            duration: 15,
            link: '/profesion-disenador-grafico',
          },
          {
            img: require('~/assets/images/index1/popular/course_9.jpg'),
            id: 'sqlAnalysis',
            category: 'programming',
            duration: 2,
            link: '/sql-de-datos',
          },
          {
            img: require('~/assets/images/index1/popular/course_10.jpg'),
            id: 'professionFullStackPythonDeveloper',
            category: 'programming',
            duration: 17,
            link: '/prof-full-stack-python',
          },
        ];
      } else {
        courses = [
          {
            img: require('~/assets/images/index1/popular/course_1.jpg'),
            id: 'professionInteriorDesigner',
            category: 'design',
            duration: 12,
            link: '/designer-de-interiores',
          },
          {
            img: require('~/assets/images/index1/popular/course_2.jpg'),
            id: 'professionGraphicDesigner',
            category: 'design',
            duration: 12,
            link: '/designer-grafico',
          },
          {
            img: require('~/assets/images/index1/popular/course_3.jpg'),
            id: 'professionDataAnalyst',
            category: 'programming',
            duration: 11,
            link: '/analista-de-dados',
          },
          {
            img: require('~/assets/images/index1/popular/course_4.jpg'),
            id: 'professionUXUIDesigner',
            category: 'design',
            duration: 10,
            link: '/uxui-designer',
          },
          {
            img: require('~/assets/images/index1/popular/course_5.jpg'),
            id: 'professionFullStackPythonDeveloper',
            category: 'programming',
            duration: 15,
            link: '/full-stack-python',
          },
          {
            img: require('~/assets/images/index1/popular/course_6.jpg'),
            id: 'professionSocialMediaManager',
            category: 'marketing',
            duration: 12,
            link: '/profissao-smm',
          },
          {
            img: require('~/assets/images/index1/popular/course_7.jpg'),
            id: 'coursePersonalStylist',
            category: 'fashion',
            duration: 4,
            link: '/personal-stylist',
          },
          {
            img: require('~/assets/images/index1/popular/course_8.jpg'),
            id: 'professionPerformanceMarketingManager',
            category: 'marketing',
            duration: 11,
            link: '/profissao-gestor-de-marketing',
          },
          {
            img: require('~/assets/images/index1/popular/course_9.jpg'),
            id: 'courseCopywriting',
            category: 'marketing',
            duration: 5,
            link: '/copywriting',
          },
          {
            img: require('~/assets/images/index1/popular/course_10.jpg'),
            id: 'professionDataScientist',
            category: 'programming',
            duration: 10,
            link: '/cientista-de-dados',
          },
        ];
      }

      this.debug({ name: 'comp courses' }, '%O', courses);
      return courses;
    },
  },

  watch: {
    async isMobile(value) {
      await this.$nextTick();

      if (!value) {
        this.initSwiper();
      } else {
        if (this.swiper !== null) {
          this.swiper.destroy();
          this.swiper = null;
        }
      }

      this.$refs.bg.src = value
        ? require('~/assets/images/index1/popular/background_mobile.png')
        : require('~/assets/images/index1/popular/background.png');
    },
  },

  mounted() {
    if (!this.isMobile) {
      this.initSwiper();
    }

    this.$refs.bg.src = this.isMobile
      ? require('~/assets/images/index1/popular/background_mobile.png')
      : require('~/assets/images/index1/popular/background.png');
  },
};
