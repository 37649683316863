//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InnerLoader from './components/innerLoader';

export default {
  components: {
    InnerLoader,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    firstPost() {
      const _value = this.$store.state?.mainPage?.blogs?.[0];
      this.debug({ name: 'comp firstPost' }, '%O', _value);
      return _value;
    },
    posts() {
      const _value = this.$store.state?.mainPage?.blogs?.slice(1, 5);
      this.debug({ name: 'comp posts' }, '%O', _value);
      return _value;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      if (this.$app.config.current.withBlog) {
        if (this.$app.config.current.tildaBlog) {
          let responseBlogs;
          try {
            responseBlogs = await this.$axios({
              method: 'get',
              url: this.$app.config.current.getBlogsUrl,
            });
          } catch (e) {
            console.error(e);
            // error({ statusCode: 500, message: 'Os trabalhos técnicos estão em andamento' });
            // throw e;
          }

          let blogs = [];
          if (responseBlogs?.data) {
            if (responseBlogs.data.status !== 'ok') {
              throw new Error(
                `An error occurred while executing the query (GetBlogs)`,
              );
            }

            blogs = responseBlogs.data.items || [];

            if (this.$app.config.current.newDomainName) {
              blogs = blogs.map((blog) => ({
                ...blog,
                item_image_full:
                  (blog.item_image_full &&
                    blog.item_image_full.replace(
                      new URL(blog.item_image_full).origin,
                      this.$app.config.current.newDomainName,
                    )) ||
                  '',
                item_link_full:
                  (blog.item_link_full &&
                    blog.item_link_full.replace(
                      new URL(blog.item_link_full).origin,
                      this.$app.config.current.newDomainName,
                    )) ||
                  '',
              }));
            }
          }

          this.$store.commit('mainPage/setBlogs', Object.assign([], blogs));
        } else {
          let responseBlogs;
          if (['br', 'mx'].includes(this.$app.config.current.code)) {
            try {
              responseBlogs = await this.$axios.get(
                `${this.$app.config.current.wordPressPosts}`,
                {
                  params: {
                    offset: 0,
                    per_page: 5,
                  },
                },
              );

              let blogs = [];
              if (responseBlogs?.data) {
                blogs = (responseBlogs.data || []).map((blog) => ({
                  ...blog,
                  formattedCreated: this.$dayjs(blog.created).format(
                    'DD.MM.YYYY',
                  ),
                }));
              }

              this.$store.commit('mainPage/setBlogs', Object.assign([], blogs));
            } catch (e) {
              console.error(e);
            }
          }
        }
      }

      this.loaded = true;
    },
    getPostImageUrl(post) {
      return post?.yoast_head_json?.og_image?.[0]?.url;
    },
    format(date, mask) {
      return this.$dayjs(date).format(mask);
    },
    utcOffset(utcString) {
      return this.$dayjs
        .utc(utcString)
        .utcOffset(this.$app.config.current.utcOffset);
    },
  },
};
