import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b02205b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./index.vue?vue&type=style&index=1&id=1b02205b&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b02205b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {D0822Header: require('/app/landing_generator/components/d0822/header.vue').default,D0822Main: require('/app/landing_generator/components/d0822/main.vue').default,D0822VideoPresentation: require('/app/landing_generator/components/d0822/VideoPresentation.vue').default,D0822CoursesAndProfessions: require('/app/landing_generator/components/d0822/coursesAndProfessions.vue').default,D0822BestCourse: require('/app/landing_generator/components/d0822/bestCourse.vue').default,D0822Studio: require('/app/landing_generator/components/d0822/studio.vue').default,D0822Professors: require('/app/landing_generator/components/d0822/professors.vue').default,D0822Partners: require('/app/landing_generator/components/d0822/partners.vue').default,D0822JobGuaranteePolicy: require('/app/landing_generator/components/d0822/jobGuaranteePolicy.vue').default,D0822About: require('/app/landing_generator/components/d0822/about.vue').default,D0822VideoReviews: require('/app/landing_generator/components/d0822/videoReviews.vue').default,D0822TextReviews: require('/app/landing_generator/components/d0822/textReviews.vue').default,D0822Webinars: require('/app/landing_generator/components/d0822/webinars.vue').default,D0822Countries: require('/app/landing_generator/components/d0822/countries.vue').default,D0822Popular: require('/app/landing_generator/components/d0822/popular.vue').default,D0822Form: require('/app/landing_generator/components/d0822/form.vue').default,D0822Blog: require('/app/landing_generator/components/d0822/blog.vue').default,D0822Footer: require('/app/landing_generator/components/d0822/footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
