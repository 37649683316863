//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    // console.log(32, require('~/assets/images/index1/webinars/webinar_1.jpg'))
    return {
      backgroundImages: [
        require('~/assets/images/index1/webinars/webinar_1.jpg'),
        require('~/assets/images/index1/webinars/webinar_2.jpg'),
        require('~/assets/images/index1/webinars/webinar_3.jpg'),
        require('~/assets/images/index1/webinars/webinar_4.jpg'),
        require('~/assets/images/index1/webinars/webinar_5.jpg'),
        require('~/assets/images/index1/webinars/webinar_6.jpg'),
        require('~/assets/images/index1/webinars/webinar_7.jpg'),
        require('~/assets/images/index1/webinars/webinar_8.jpg'),
        require('~/assets/images/index1/webinars/webinar_9.jpg'),
        require('~/assets/images/index1/webinars/webinar_10.jpg'),
        require('~/assets/images/index1/webinars/webinar_11.jpg'),
        require('~/assets/images/index1/webinars/webinar_12.jpg'),
      ],
      swiper: null,
    };
  },
  computed: {
    m() {
      return this.hash(this.data.name + this.data.type);
    },
  },
  methods: {
    hash(str) {
      if (str.length === 0) return 0;

      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0;
      }

      return Math.abs(hash % this.backgroundImages.length);
    },
  },
};
